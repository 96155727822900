.App {
}
h1 {
  @apply text-5xl;
}
h2 {
  @apply text-xl;
}
h3 {
  @apply text-lg;
}
.chat-container{
  /*余白下50px*/
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
body, html {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.search-bar {
  position: fixed;
  bottom: 0;
  width: calc(100% - 20px);
  background-color: rgba(244, 244, 244, var(--tw-bg-opacity));
  --tw-bg-opacity:1;
  border-top: 1px solid #ccc;
  padding: 10px;
  display:flex;
}

.search-bar button{
  margin-left:-40px;
  outline:none;
  border:none;
  background: transparent;
}

/*search-bar buttonの無効化時にグレーアウト*/
.search-bar button:disabled {
  color: #ccc;
}

.search-bar textarea {
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
}

/*search-barのplaceholderを左側中央寄せ*/
.search-bar textarea::placeholder {
  text-align: left;
  vertical-align: middle;
}

/* レスポンシブデザインの設定 */
@media (max-width: 768px) {
  .search-bar {
    padding: 8px;
  }

  .search-bar textarea {
    width: calc(100% - 16px);
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .search-bar {
    padding: 6px;
  }

  .search-bar textarea {
    width: calc(100% - 12px);
    padding: 6px;
  }
}

/* タイプライター */
@keyframes typewriter {
  from { width: 0; }
  to { width: 100%; }
}

.animate-typewriter {
  animation: typewriter 4s steps(40) 1s infinite;
}